:root {
    --background-light: #f9f9fb !important;
    --text-strong: #424242 !important;
    --primary-color: #007bff !important;
    --primary-hover: #0056b3 !important;
    --danger-color: #e63946 !important;
    --danger-hover: #d62839 !important;
    --warning-color: #f4a261 !important;
    --warning-hover: #cc6d38 !important;
    --success-color: #2a9d8f !important;
    --success-hover: #21867a !important;
    --shadow-base: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    --shadow-hover: 0 6px 10px rgba(0, 0, 0, 0.15) !important;
    --transition-base: 0.3s ease !important;
    --border-radius: 8px !important;
    --button-padding: 10px 20px !important;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.customDispSessionCollection {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: flex-start !important;
    justify-content: center !important;
    padding: 20px !important;
    gap: 20px !important;
}

.customDispSessionCollection > * {
    box-sizing: border-box !important;
    min-width: 260px !important;
    max-width: 320px !important;
    height: 320px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-around !important;
    margin: 0 10px !important;
}

.sessionContainer {
    background-color: var(--background-light) !important;
    border-radius: var(--border-radius) !important;
    box-shadow: var(--shadow-base) !important;
    padding: 20px !important;
    transition: transform var(--transition-base), box-shadow var(--transition-base) !important;
    width: 100% !important;
    height: 100% !important;
    font-family: var(--font-family) !important;
}

.sessionContainer:hover {
    transform: translateY(-5px) !important;
    box-shadow: var(--shadow-hover) !important;
}

.sessionType, .sessionDate {
    font-weight: 700 !important;
    color: var(--text-strong) !important;
}

button {
    font-family: var(--font-family) !important;
    padding: var(--button-padding) !important;
    border: none !important;
    border-radius: var(--border-radius) !important;
    cursor: pointer !important;
    transition: background-color var(--transition-base), transform var(--transition-base) !important;
}

.viewButton, .deleteButton, .updateButton, .todayButton {
    color: white !important;
}

.viewButton {
    background-color: var(--primary-color) !important;
}

.viewButton:hover {
    background-color: var(--primary-hover) !important;
    transform: scale(1.05) !important;
}

.deleteButton {
    background-color: var(--danger-color) !important;
}

.deleteButton:hover {
    background-color: var(--danger-hover) !important;
    transform: scale(1.05) !important;
}

.updateButton {
    background-color: var(--warning-color) !important;
}

.updateButton:hover {
    background-color: var(--warning-hover) !important;
    transform: scale(1.05) !important;
}


.publishButton[disabled] {
    background-color: #ccc; /* Grey out the button */
    color: #666;
    cursor: not-allowed; /* Change cursor to indicate disabled state */
    position: relative; /* Needed for absolute positioning of the tooltip */
    overflow: visible; /* Allow the tooltip to be visible outside the button */
}

.publishButton[disabled]::after {
    content: "Session already published"; /* Tooltip text */
    visibility: hidden;
    opacity: 0;
    width: 200px; /* Adjust width to fit longer text if necessary */
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px; /* Add horizontal padding for better text fitting */
    font-size: 14px; /* Make text size adequate for readability */
    position: absolute;
    z-index: 10; /* Ensure tooltip is above other elements */
    bottom: 100%; /* Position the tooltip above the button */
    left: 50%;
    transform: translateX(-50%) translateY(-10px); /* Adjust vertical position for visual separation */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transitions for opacity and movement */
}

.publishButton[disabled]:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-20px); /* Raise the tooltip slightly on hover */
}

/* Enhance shadow for better separation from button and surrounding elements */
.publishButton[disabled]::after {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
}

