/* DispFeedBottomNavBar.css */
.bottomNavBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
}

.navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 5px;
    border: none;
    background: none;
    color: #333;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.navItem:hover {
    background-color: #f0f0f0;
    color: #007bff;
}

.icon {
    font-size: 20px;
    margin-bottom: 5px;
}

.label {
    font-size: 14px;
}
