/* verify.css */

.verify-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #ffffff; /* Bright background to contrast dark theme */
    color: #333;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* Stronger shadow for depth */
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    margin: auto;
    border: 1px solid #ddd; /* Slight border for better definition */
}

.verify-container.verified {
    background-color: #e8f5e9; /* Soft green background for verified users */
    border-color: #c8e6c9; /* Border to match */
}

h2 {
    color: #2c3e50;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 20px;
}

button {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    outline: none;
    display: block;
    background-color: #3498db; /* Consistent blue for initial state */
}

button:hover {
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

button.hidden {
    display: none;
}

.next-button {
    background-color: #4CAF50; /* Green button for proceed */
}

.next-button:hover {
    background-color: #45a049;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

button:not(.next-button):hover {
    background-color: #2980b9;
}
