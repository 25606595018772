.settings-container, .change-password-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 30px;
    background-color: #282c34; /* Unified dark background for all settings */
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    color: #ffffff; /* Consistent white font color for better contrast */
}

.settings-form, .change-password-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FAF9F6; /* Light background for form sections */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
}

.settings-label, .change-password-label {
    font-size: 18px; /* Consistent increased font size */
    color: #273a4d; /* Darker color for headers */
    margin-bottom: 10px;
}

.settings-input, .change-password-input {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Full width for input fields */
    box-sizing: border-box; /* Include padding and border in the width */
}

.settings-button, .change-password-button {
    display: block;
    width: auto;
    max-width: 200px;
    padding: 12px 20px;
    margin: 20px auto;
    border-radius: 30px;
    background-color: #007bff; /* Unified blue background for all buttons */
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.3s ease;
    border: 1px solid transparent;
}

.settings-button:hover, .change-password-button:hover {
    background-color: white;
    color: #0069cc; /* Slightly darker blue for hover */
    border-color: #0069cc;
}

.error-message, .change-password-error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
}

.login-redirect-button {
    background: none;
    border: none;
    color: #0099cc;
    font-size: 1rem;
    margin-top: 20px;
    display: block;
}

.login-redirect-button:hover {
    color: #0088cc;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .settings-container, .change-password-container {
        width: 90%;
        padding: 15px;
    }

    .settings-button, .change-password-button {
        width: 100%;
        max-width: none;
    }
}
