.workoutCreateForm {
  width: 100% !important;
  max-width: 500px !important;
  margin: 20px auto !important;
  background-color: #ffffff !important;
  color: #333333 !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  padding: 40px !important;
  font-size: 16px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
}

.formField {
  width: 100% !important;
  padding: 10px !important;
  margin-bottom: 20px !important;
  border: 2px solid #e2e8f0 !important;
  border-radius: 8px !important;
  font-size: 16px !important;
}

.submitButton {
  width: auto !important;
  padding: 12px 20px !important;
  margin-top: 10px !important;
  border: none !important;
  border-radius: 8px !important;
  background-color: #007bff !important;
  color: #ffffff !important;
  font-size: 18px !important;
  cursor: pointer !important;
  align-self: center !important;
  transition: background-color 0.3s, transform 0.2s !important;
}

.submitButton:hover {
  background-color: #0056b3 !important;
  transform: scale(1.05) !important;
}

.App-header {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 20px !important;
}

.Big-Button {
  margin-top: 20px !important;
}
