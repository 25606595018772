.profile-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 30px;
    background-color: #d3d3d3;
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    color: #3a3a3a; /* Slightly darker font color */
}

.profile-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    color: #273a4d; /* Slightly darker header color */
}

.profile-picture {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    margin: 20px auto;
    display: block;
    object-fit: cover;
    border: 4px solid #007bff;
}

.profile-details, .activity-section, .goals-section, .friends-section {
    background-color: #FAF9F6;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    padding: 20px;
    margin-bottom: 30px;
}

.profile-details p, .activity-section p, .goals-section p, .friends-section p {
    line-height: 1.6;
    color: #5a6268; /* Slightly darker paragraph color */
}

.section-title {
    font-size: 18px;
    color: #0069cc; /* Slightly darker section title color */
    font-weight: 500;
    margin-bottom: 15px;
}

.button {
    display: block;
    width: auto;
    max-width: 200px;
    padding: 12px 20px;
    margin: 20px auto;
    border-radius: 30px;
    background-color: #007bff;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.3s ease;
    border: 1px solid transparent;
}

.button:hover {
    background-color: white;
    color: #0069cc; /* Slightly darker blue for hover */
    border-color: #0069cc;
}


.profile-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;  /* This creates the circle shape */
    object-fit:cover;   /* Ensures the image covers the space without being stretched */
    border: 3px solid #fff; /* Optional: adds a white border around the image */
    box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* Optional: adds a slight shadow for better contrast */
  }
  

@media (max-width: 768px) {
    .profile-container {
        width: 90%;
        padding: 15px;
    }

    .profile-header {
        font-size: 20px;
    }

    .button {
        width: 100%;
        max-width: none;
    }
}
