.formContainer {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
  padding: 40px;
  display: grid;
  gap: 20px;
}

.formField {
  display: flex;
  flex-direction: column;
}

.formField label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.formField input {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submitButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #0056b3;
}
