/* Base styles for the navigation bar */
.dispSessionFeedNavBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    background: #f0f2f5;
    color: #333;
    margin-top: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    font-family: 'Roboto', sans-serif;
    border-radius: 10px;
}

.navContainer {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.logo {
    font-size: 24px;
    font-weight: 700;
    color: #333;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.navLinks {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 20px;
}

.navLinks li {
    padding: 0 10px; /* Use padding instead of margin for spacing */
}

.navLinks li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    padding: 8px 12px;
    transition: all 0.3s ease;
    display: block;
    border-radius: 4px;
}

.navLinks li a:hover,
.navLinks li a:focus {
    color: #fff;
    background-color: #0056b3; /* Darker blue for better visibility and contrast */
    outline: none;
}

@media (max-width: 768px) {
    .navContainer {
        flex-direction: column;
        padding: 10px 0;
    }

    .logo {
        margin-bottom: 10px;
    }

    .navLinks {
        flex-direction: column;
        width: 100%;
        padding: 0;
    }

    .navLinks li {
        text-align: center;
        padding: 8px 0; /* Adjust padding for better touch targets */
    }

    .navLinks li a {
        width: 100%; /* Full width links for easier tap targets */
    }
}

/* Focus styles for keyboard navigation */
.navLinks li a:focus-visible {
    outline: 3px solid #0056b3;
    outline-offset: 2px;
}

.navLinks li a:focus:not(:focus-visible) {
    outline: none;
}
