.settings-page-container {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    width: 100%; /* Full width of the viewport */
    min-height: 100vh; /* Full height of the viewport */
    background-color: #282c34; /* Dark background for the whole container */
    padding: 20px; /* Padding to prevent content from touching the edges */
}

.settings-container {
    display: flex; /* Ensures that sidebar and content are side by side */
    width: auto; /* Automatically adjust width based on child content */
    max-width: 960px; /* Maximum width of the settings area */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for depth and emphasis */
    border-radius: 8px; /* Rounded corners */
}

.settings-sidebar {
    width: 250px; /* Fixed width for the sidebar */
    background-color: #34495e; /* Slightly lighter shade for contrast */
    padding: 20px;
    color: #ffffff; /* White text for better readability */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Shadow for visual separation */
    overflow-y: auto; /* Allows scrolling if content exceeds screen height */
}

.settings-content {
    flex-grow: 1; /* Takes remaining horizontal space */
    padding: 40px; /* Ample padding for content */
    background-color: #282c34; /* Uniform with the container */
    color: #ffffff; /* White text for readability */
    overflow-y: auto; /* Scrollable content */
}

.settings-sidebar button {
    display: block;
    width: 100%; /* Full width of the sidebar */
    padding: 15px;
    margin-bottom: 10px; /* Spacing between buttons */
    background-color: #007bff; /* Bright blue for buttons */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Readable text size */
    transition: background-color 0.2s; /* Smooth hover effect */
}

.settings-sidebar button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.settings-sidebar button:focus {
    outline: none; /* Remove focus outline */
    box-shadow: 0 0 0 2px rgba(0, 75, 255, 0.5); /* Custom focus effect */
}

.settings-sidebar::-webkit-scrollbar,
.settings-content::-webkit-scrollbar {
    width: 8px; /* Narrow scrollbars */
}

.settings-sidebar::-webkit-scrollbar-thumb,
.settings-content::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Blue scrollbars */
    border-radius: 4px;
}

.settings-sidebar::-webkit-scrollbar-track,
.settings-content::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Light grey track */
}

.error-message {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}

.success-message {
    color: #4CAF50; /* Green color for success */
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}