.big-button {
  padding: 15px 30px !important;
  font-size: 20px !important;
  border-radius: 12px !important;
  cursor: pointer !important;
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  font-weight: 600 !important;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  outline: none !important;
}

.big-button:hover, .big-button:focus {
  background-color: #0056c4 !important;
  color: white !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2) !important;
}

.big-button:focus {
  box-shadow: 0 0 0 4px rgba(0, 117, 255, 0.5) !important;
}
