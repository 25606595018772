/* ForgotPassword.css */

.forgot-password-container {
    max-width: 360px;
    margin: 5% auto;
    padding: 25px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.forgot-password-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #2C3E50;
    font-size: 24px;
    font-weight: 600;
}

.forgot-password-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.forgot-password-form label {
    font-size: 14px;
    color: #6c757d;
}

.forgot-password-form input {
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    font-size: 14px;
    background-color: #eaeff4;
}

.forgot-password-form input:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(128, 189, 255, 0.25);
}

.forgot-password-form button {
    padding: 12px;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.forgot-password-form button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.forgot-password-message {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    color: #28a745;
}

.error-message {
    color: #dc3545;
    background-color: #f8d7da;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #f5c2c7;
    margin-top: 15px;
    text-align: center;
}

.back-button {
    display: block;
    margin: 20px auto;
    padding: 8px 16px;
    background-color: #6c757d;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.back-button:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
}
