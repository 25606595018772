.bigButton {
    width: auto;
    height: 50px;
    padding: 0 35px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #ffffff;
    background: linear-gradient(145deg, #4caf50, #43a047);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bigButton:hover {
    background: linear-gradient(145deg, #369638, #2e7d32);
    transform: translateY(-4px) scale(1.03);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.bigButton:focus {
    outline: none;
    background: linear-gradient(145deg, #2e7d32, #27632a);
    box-shadow: 0 0 0 4px rgba(72, 161, 77, 0.6);
}

.bigButton:active {
    transform: translateY(-1px) scale(0.97);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
