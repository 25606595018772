/* NotFoundPage.css */
.not-found-container {
    text-align: center;
    margin-top: 10vh;
    padding: 20px;
    color: #333;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.not-found-container h1 {
    font-size: 72px;
    margin-bottom: 20px;
    color: #007bff;
}

.not-found-container p {
    font-size: 24px;
    margin-bottom: 30px;
}

.not-found-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.not-found-button:hover {
    background-color: #0056b3;
}
