.workoutsSummaryBox {
  background: linear-gradient(to top right, #ffffff, #f3f4f6);
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 25px;
  width: 320px;
  margin: 30px auto;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.workoutsSummaryBox:hover {
  transform: scale(1.08);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
}

.workoutsSummaryBox__title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 25px;
  line-height: 1.2;
}

.workoutsSummaryBox__item {
  font-size: 1.2rem;
  color: #4a4a4a;
  margin-bottom: 15px;
}

.workoutsSummaryBox__item--highlight {
  color: #1a85ff;
  font-weight: 600;
}

.workoutsSummaryBox__item span {
  font-weight: bold;
}
