.centered {
    justify-content: center;
}

.viewWorkoutsHeader {
    width: 100%;
    max-width: 260px;
    min-height: 180px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    background-color: #f8f8f8;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    margin: 15px;
    overflow: hidden;
}

.sessionInfoText {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    margin: 0;
    padding-bottom: 25px;
    text-align: center;
}

.backButton {
    width: auto;
    padding: 12px 24px;
    font-size: 20px;
    font-weight: bold;
    background-color: #ff5c5c;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
    align-self: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.backButton:hover, .backButton:focus {
    background-color: #e53e3e;
    transform: translateY(-3px) scale(1.07);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.WorkoutSelector, .WorkoutSelecter {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    padding: 10px 16px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 6px;
    outline: none;
    width: 100%;
    max-width: 320px;
    box-sizing: border-box;
}

.WorkoutSelector:focus, .WorkoutSelecter:focus {
    border-color: #0066cc;
    box-shadow: 0 0 0 3px rgba(0, 98, 204, 0.3);
}

.autocomplete-field {
    position: relative;
}

.autocomplete-suggestions {
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-height: 320px;
    overflow-y: auto;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
    margin-top: 6px;
}

.suggestion-item {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    list-style-type: none;
    background-color: #fff;
    color: #000;
}

.suggestion-item:hover, .suggestion-item:focus {
    background-color: #f0f0f0;
}
