.workoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  padding: 20px 30px;
  background-color: #f8f8f8;
  border: 1px solid #dcdcdc;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.workoutContainer:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.workoutItem {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #2c3e50;
  line-height: 1.6;
  margin: 0 15px;
}

.deleteButton {
  background-color: #ff5c5c;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.deleteButton:hover {
  background-color: #ff3838;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

.deleteButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 88, 88, 0.6);
}

.timer {
  background-color: #4caf50;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.timer:hover {
  background-color: #388e3c;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

.timer:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.6);
}
