/* CustomWorkoutCreateForm.css */

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  max-width: 480px;
  margin: 0 auto;
}

.form-field {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 15px;
}

.form-field:focus-within {
  border-color: #007bff;
}

.form-field label {
  color: #007bff;
  font-size: 14px;
  font-weight: 600;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.clear-button, .submit-button {
  border-radius: 8px;
  padding: 12px 24px;
  font-weight: bold;
  cursor: pointer;
}

.clear-button {
  background-color: #f8f9fa;
  color: #212529
}